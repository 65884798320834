import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LocalStorageService } from 'src/app/lib/services';
import { storageKeys } from 'src/app/lib/constants';

export const headerInterceptor: HttpInterceptorFn = (request, next) => {
  const storageService = inject(LocalStorageService);
  const userToken = storageService.getData<string>(storageKeys.userToken);

  if (userToken != null) {
    const clonedRequest = request.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${userToken}`,
      },
    });

    return next(clonedRequest);
  }

  return next(request);
};
