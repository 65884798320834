import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { pathName } from './lib/constants/path-name';
import { Meta } from '@angular/platform-browser';
// import { appImages } from './lib/theme';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private _meta: Meta) {}
  title = 'mt-Ticket';
  protected readonly metaDomain = environment.metaDomain;
  protected readonly webrtUrl = this.metaDomain + pathName.webrtUrl;
  protected readonly plannerUrl = this.metaDomain + pathName.plannerUrl;

  ngOnInit(): void {
    this.appendScript(this.webrtUrl);
    this.appendScript(this.plannerUrl);
    // this.setMetaTags();
  }

  appendScript(src: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
  }

  // setMetaTags(): void {
  //   this._meta.addTags([
  //     { property: 'og:image', content: appImages.imgMetaTicketThumbnail },
  //     { property: 'twitter:image', content: appImages.imgMetaTicketThumbnail },
  //   ]);
  // }
}
