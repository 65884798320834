import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export const serverErrorInterceptor: HttpInterceptorFn = (request, next) => {
  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      // console.log(error);
      if ([HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.status)) {
        return throwError(() => error);
      }
      return throwError(() => error);
    }),
  );
};
