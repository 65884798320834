import { Routes } from '@angular/router';
import { routeName } from 'src/app/lib/constants';

export const routes: Routes = [
  {
    path: routeName.root,
    loadComponent: () => import('src/app/pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: routeName.event,
    loadComponent: () => import('./pages/event/event.component').then((m) => m.EventComponent),
  },
  {
    path: routeName.faq,
    loadComponent: () => import('./pages/account/account.component').then((m) => m.AccountComponent),
  },
  {
    path: routeName.login,
    loadComponent: () => import('src/app/pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: routeName.register,
    loadComponent: () => import('src/app/pages/register/register.component').then((m) => m.RegisterComponent),
  },
  {
    path: routeName.contact,
    loadComponent: () => import('src/app/pages/contact/contact.component').then((m) => m.ContactComponent),
  },
  {
    path: routeName.aboutUs,
    loadComponent: () => import('src/app/pages/about-us/about-us.component').then((m) => m.AboutUsComponent),
  },
  {
    path: routeName.booking,
    loadComponent: () => import('src/app/pages/booking/booking.component').then((m) => m.BookingComponent),
  },
  {
    path: `${routeName.show}/:id`,
    loadComponent: () => import('./pages/event-detail/event-detail.component').then((m) => m.EventDetailComponent),
  },
  {
    path: routeName.notFound,
    loadComponent: () => import('src/app/pages/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
  {
    path: routeName.policyRegulation,
    loadComponent: () =>
      import('src/app/pages/policy-regulation/policy-regulation.component').then((m) => m.PolicyRegulationComponent),
  },
  {
    path: routeName.policyInformationSecurity,
    loadComponent: () =>
      import('src/app/pages/policy-information-security-user/policy-information-security-user.component').then(
        (m) => m.PolicyInformationSecurityUserComponent,
      ),
  },
  {
    path: '**',
    redirectTo: routeName.notFound,
  },
];
